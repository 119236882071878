import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ExternalContact, Opportunity_ExternalContact, Request, Request_ExternalContact } from '../request.model';
import chroma from "chroma-js";
import { Team } from 'app/+client-admin/clients/team.model';
import { User } from 'app/+client-admin/users/user.model';
import { Client } from 'app/+client-admin/clients/client.model';
import { CustomerCardComponent } from 'app/+client-admin/members/member-card/member-card.component';
import { Utils } from '../../../shared/utils/Utils'
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import cuid from 'cuid';
import { UpsertExternalContactGqlService } from '../requests-gql/insert-externalContact-gql.service';
import { InsertOppertunityExternalContactGqlService } from '../requests-gql/insert-opportunity-externalContact-gql.service';
import { DynamicFieldsetComponent } from 'app/+client-admin/dynamic-fields/dynamic-fieldset/dynamic-fieldset.component';
import { InfiniteSelectConfig } from 'app/shared/infinite-select/infinite-select.component';
import { RequestUsersGqlService } from '../requests-gql/request-users-list-gql.service';
import { StrategicInitiativeTaggerComponent } from '../../strategic-initiatives/strategic-initiative-tagger/strategic-initiative-tagger.component';
import _ from 'lodash';


@Component({
  selector: 's5-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private upsertExternalContactGql: UpsertExternalContactGqlService,
    private insertOpportunityExternalContactGql: InsertOppertunityExternalContactGqlService,
    protected requestUsersGqlService: RequestUsersGqlService
  ) { }

  chm = chroma;
  activityTypesOnRequest = [];

  @Input() isReadOnly: boolean = false;
  @Input() request: Request;
  @Input() requestStatuses: { value: string }[];
  @Input() activityTypes;
  @Input() teams: Team[];
  @Input() showAssignedTo: boolean = true;
  @Input() showRequester: boolean = true;
  @Input() client: Client;
  @Input() requestId: string; // needed by dynamic fields

  @ViewChild('dynamicFieldset') public dynamicFieldset:DynamicFieldsetComponent
  @ViewChild('saTagger') public saTagger:StrategicInitiativeTaggerComponent

  sfOpportunityID: string;
  sfOpportunityName: string;
  sfOpportunityURL: string;
  sfContactID: string[];
  sfContactEmailAddress: string[];
  sfContactName: string[];
  sfContactTitle: string[];
  sfContactCompany: string[];
  sfAccountID: string[];
  sfUserID: string;

  userList: User[] = [];

  notesTray: any = {
    id: 'note',
    trayTitle: 'Request Notes',
    trayTitleTooltip: '',
    innerTrayTitle: '',
    innerTrayProfileTitle: '',
    trayWidth: 500,
    trayWidthInner: 500,
  };

  embed;
  opportunityContacts: Opportunity_ExternalContact[] = [];
  requestContacts: Request_ExternalContact[] = [];

  cleanupDate = Utils.cleanupDate;

  private assignedToConfig: InfiniteSelectConfig = {
    entityName: 'User',
    key: 'id',
    bindLabel: 'name',
    multiple: true,
    clearable: true,
    closeOnSelect: false,
    placeholder: 'Select Users',
    // appendTo: 's5-request-create-edit',
    dropdownPosition: 'bottom'
  }

  ngOnInit(): void {
    // Set up assignedToConfig
    const assignedToList = this.request.assignedTo_users?.map((assignedToRequest: any) => assignedToRequest.assignedTo) || [];
    this.assignedToConfig.value = _.uniq([...assignedToList, this.request.assignedTo]).filter((user: User) => user);
    this.assignedToConfig.variables = { clientId: this.client.id };
    this.assignToValueChange(this.assignedToConfig.value);

    // Set up external contacts
    this.request.externalContacts ||= [];
    this.request.externalContacts.map((REC: any) => {
      REC.externalContact.label = `${REC.externalContact.name} - ${REC.externalContact.companyName}`
      delete REC.__typename;
    });
    
    // Embed SF parameters
    this.sfOpportunityID = this.route.snapshot.queryParamMap.get('OpportunityId'); // =0065w000022MGs8AAG
    this.sfOpportunityName = this.route.snapshot.queryParamMap.get('OpportunityName'); // =Test+Opp+001
    this.sfOpportunityURL = this.route.snapshot.queryParamMap.get('OpportunityURL'); // =https%3A%2F%2Fslapfive-test-package-dev-ed.my.salesforce.com%2F0065w000022MGs8AAG&UserName=standard.user.test.package%40slapfive.com
    this.sfContactID = (this.route.snapshot.queryParamMap.get('ContactID') || '').split(','); // =0035w000037ahqkAAA&ContactFullName=Contact+001
    this.sfContactEmailAddress = (this.route.snapshot.queryParamMap.get('ContactEmailAddress') || '').split(','); // =testcontact001%40sample.com
    this.sfContactName = (this.route.snapshot.queryParamMap.get('ContactFullName') || '').split(',');
    this.sfContactTitle = (this.route.snapshot.queryParamMap.get('ContactTitle') || '').split(',');
    this.sfContactCompany = (this.route.snapshot.queryParamMap.get('ContactCompany') || '').split(',');
    this.sfAccountID = (this.route.snapshot.queryParamMap.get('AccountID') || '').split(',');
    this.sfUserID = this.route.snapshot.queryParamMap.get('UserId');

    this.embed = !!this.sfOpportunityID || this.route.snapshot.data['isEmbed'];
    // we are being called from salesforce; so populate drop down (customers) with passed parameters
    if (this.sfContactID && this.sfContactID[0] && this.sfContactID[0] !== '') {
      // check that we have all the same length sf contact elements
      if ([this.sfContactEmailAddress, this.sfContactName, this.sfContactCompany, this.sfAccountID].find((item: any) => {
        return item.length !== this.sfContactID.length;
      })) {
        alert("An error has occurred.  Please contact Slapfive support.");
      }
      this.sfContactID.map(async (cid, idx) => {
        const clientExternalContact = this.client.externalContacts.find((clientExtContact: ExternalContact) => clientExtContact.externalContact_id === cid);
        const externalContact: any = {
          ...clientExternalContact || {},
          externalContact_id: cid,
          client_id: this.client.id,
          name: this.sfContactName[idx],
          title: this.sfContactTitle[idx],
          email: this.sfContactEmailAddress[idx],
          companyName: this.sfContactCompany[idx],
          account_id: this.sfAccountID[idx],
          label: `${this.sfContactName[idx]} - ${this.sfContactCompany[idx]}`
        }
        delete externalContact.__typename;

        // save or update new external contact
        externalContact.id ||= cuid();
        const variables = {...externalContact};
        delete variables.label;
        await this.upsertExternalContactGql.mutate(variables).subscribe(() => {});

        const oppExternalContact: Opportunity_ExternalContact = this.request.opportunity?.externalContacts.find((oppContact: Opportunity_ExternalContact) => oppContact.externalContact_id === cid);
        const opportunityExternalContact: any = {
          ...oppExternalContact || {},
          opportunity_id: this.sfOpportunityID,
          externalContact_id: cid,
          externalContact: externalContact
        }
        delete opportunityExternalContact.__typename;
        // Save new opportunity external contact
        if (!opportunityExternalContact.id) {
          opportunityExternalContact.id = cuid();
          const variables:any = {...opportunityExternalContact};
          delete variables.externalContact;
          this.insertOpportunityExternalContactGql.mutate(variables).subscribe(() => {});
        }
        delete opportunityExternalContact.__typename;
        this.opportunityContacts.push(opportunityExternalContact);
      });

    } else {
      this.opportunityContacts = this.request.opportunity && this.request.opportunity.externalContacts || [];
      this.opportunityContacts.map((oppContact: any) => {
        const externalContact = oppContact.externalContact;
        externalContact.label = `${externalContact.name} - ${externalContact.companyName}`
        delete oppContact.__typename;
        delete oppContact.externalContact.__typename;
      });
    }

    this.activityTypesOnRequest = this.client.activityTypes.filter(at => at.includeOnRequestForm);

    this.assignedToConfig.whereClause = {
      clientRoles: {
        ClientRole: {client_id: {_eq: this.client.id}},
        allowRequestAssignment: {_eq: true}
      }
    }
  }

  updateMembers(value): void {
    if (value) {
      this.request.activityType_id = value;
      this.request.activityType = this.client.activityTypes.find((activityType: any) => activityType.id === value);
    }
  }

  linkToOpportunity() {
    if (this.request.opportunity && this.request.opportunity.url) {
      window.open(this.request.opportunity.url, '_blank')
    }

  }

  protected assignToValueChange(users: User[]) {
    delete this.request.assignedTo;
    delete this.request.assignedToId;
    this.request.assignedTo_users = users.map((user: User) => {
      return {
        assignedTo: user,
        request: this.request,
        client: this.client
      }
    });
  }

}
