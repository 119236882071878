import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Subscription } from 'apollo-angular';

import { DynamicFieldModel } from '../dynamic-field.model';

// This is a supported but uncommon (for slapfive) usage of Hasura; so
//  here is some explanation:

// In English: Get the DynamicFields configured for an entity type (eg MEMBER)
//  and also get any dynamic field values that have been set (for a specific entity
//  instance).
//  Use Case: For showing a dynamic fieldset on an entity; we need the defined dynamic
//  fields for this type of entity (in this client instance) AND any data that may have
//  already been set for this particular entity.
//
//  Query Details:
//  The or operator in the where clause is saying, "match dynamic fields
//  by entity type and entity id that have data (dynamic data) and also that
//  don't have any data; (dynamic field defined but no data (DynamicFieldData)
//  recorded for said entity)"


export const LIST_ENTITY_DYNAMIC_FIELDS_QUERY_NAME = 'ListEntityDynamicFieldsQuery';

export interface ListEntityDynamicFieldsSubscriptionResult {
  DynamicField: [DynamicFieldModel];
}

@Injectable({
  providedIn: 'root'
})
export class ListEntityDynamicFieldsService extends Subscription<ListEntityDynamicFieldsSubscriptionResult> {

  document = gql`
    subscription ${LIST_ENTITY_DYNAMIC_FIELDS_QUERY_NAME} ($entityName: String!, $entityId: String!) {
      DynamicField(
          where: { entity_name: { _eq: $entityName } },
          order_by: [{ sort: asc},{label: asc}]  )
      {
        id
        client_id
        label
        description
        required
        type
        dataName: data_name
        entityName: entity_name
        dynamicFieldPickListValues(order_by: { order: asc }) {
          id
          name
          order
        }
    		dynamicFieldDataMappings(where: { _or: [ { entity_id: { _in: [$entityId] }} ] } ) {
          id
          dynamicFieldData {
            id 
            value
            company {
              id
              name
            }
            customer {
              id
              name
            }
            user {
              id
              name
            }
            activityType {
              id
              name
            }
            pickListValue {
              id
              name
            }
          }
        }
      }
    }
  `;

}


