import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { User } from '../user.model';
import gql from 'graphql-tag';

export const FIND_USER_QUERY_NAME = 'FindUserQuery';

export interface FindUserQueryResult {
  User: [User];
}

@Injectable({
  providedIn: 'root'
})
export class FindUserGqlService extends Query<FindUserQueryResult> {

  document = gql`
  query ${FIND_USER_QUERY_NAME} ($email:String!) {

    User( where: { email: {_eq: $email }}) {
      id
      auth0UserId
      email
      name
      phone
      superadmin
      clientRoles {
        notifyNewPromptResponse
        notifyNewRequests
        allowRequestAssignment
        prefilterMembers
        ClientRole {
          id
          role
          client {
            id
          }
        }
      }
      currentRole {
        id
        role
      }
    }

  }
  `;
}
