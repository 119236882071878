// this is the prompt service which facilitates communications
//  with the server to send prompt invites

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { LoadUserGqlService } from './user-gql/load-user-gql.service';
import { FindUserGqlService } from './user-gql/find-user-gql.service';
import { User } from './user.model';


interface Msg {
  msg: string;
  date: Date;
}

@Injectable({providedIn: 'root'})
export class UserService {

  cachedUser:User;

  constructor(
    private http: HttpClient,
    private loadUserGqlService: LoadUserGqlService,
    private findUserGqlService: FindUserGqlService
    ) { }

  async findUserByEmail(email: string) {
    if (!email) {
      return null;
    }
    return this.http.get(`/api/user/${email.toLowerCase()}`);
  }

  async getUser() {
    if (this.cachedUser) {
      return this.cachedUser;
    } else {
      const resp = await firstValueFrom(this.loadUserGqlService.watch().valueChanges);
      this.cachedUser = cloneDeep(resp['data'].current_user[0]);
      return this.cachedUser;
    }
  }

  inviteNewUser(user: any) {
    return this.http
      .post('/api/email/invite-user', user);
  }

  createNewUser(user: any) {
    return this.http
      .post('/api/user', user);
  }

  async getPermissions() {
    const user: User = await this.getUser();
    return {
      'canEditBoardTemplate': user.superadmin || user.currentRole.role === 'Admin'
    }
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
