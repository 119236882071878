import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class CreateDynamicFieldService extends Mutation {
  document = gql`
    mutation(
      $id: String!
      $label: String!
      $type: String!
      $description: String
      $dataName: String!
      $entityName: String!
      $required: Boolean!
  ) {
    insert_DynamicField_one(
      object: {
        id: $id
        label: $label
        type: $type
        description: $description
        data_name: $dataName
        entity_name: $entityName
        required: $required
      }
    ) {
        id
      }
  }`;

}
