import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { DynamicFieldModel } from '../dynamic-field.model';
import { DynamicFieldComponent } from '../dynamic-field/dynamic-field.component';
import { ListEntityDynamicFieldsService } from '../dynamic-fields-gql/list-entity-dynamic-fields.service';

@Component({
  selector: 's5-dynamic-fieldset',
  templateUrl: './dynamic-fieldset.component.html',
  styleUrls: ['./dynamic-fieldset.component.scss']
})
export class DynamicFieldsetComponent implements OnInit {

  @Input() entityName = 'MEMBER';
  @Input() entityId:string;
  @Input() classInput:string = '';
  @Input() requiredOnly:boolean = false;

  @ViewChildren('df') dfs:DynamicFieldComponent[];

  dynamicField$:Observable<DynamicFieldModel[]>;

  constructor(private listEntityDynamicFieldsService:ListEntityDynamicFieldsService) {

  }

  ngOnInit(): void {

    const vars = {
      entityName: this.entityName,
      entityId: this.entityId || ""
    };

    this.dynamicField$ = this.listEntityDynamicFieldsService.subscribe(vars)
      .pipe(map(({ data }) => {
        return data.DynamicField;
      }));
  }

  async saveAll(entity?: any) {
    let changes: Observable<any>[] = [];
    this.dfs.map(df => {
      changes = changes.concat(df.save(entity));
    });
    const results = await forkJoin(changes).toPromise();
    return results;
  }

  missingRequired() {
    return this.dfs.find((df) => {
      return df.dynamicField.required && (!df.value || df.value.length == 0 || df.value[0].id == "");
    }) != undefined;
  }

}
