// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamicFieldContainer {
  background-color: #CCCCCC;
  padding: 0.5em;
}

.form-check-input {
  width: 4rem; /* adjust as needed */
  height: 2rem; /* adjust as needed */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
